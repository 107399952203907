import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'xdOhT4c4CvOqO6a6ERfo73eS7Ltu19jlfqvY',
  sdkSecret: 'MXQgVal3zDao9EDPVkalXNYkUwfE4T9Szbm0',
  topic: 'cadence-development',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '',
  signature: '',
};


console.log(`name: ${devConfig.name}.`);
